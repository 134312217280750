.split-pane-visible > .split-pane-side {
  max-width: 100px !important;
}
.admin-header {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;

  font-weight: 800;
  font-size: 20px;
  color: #808080;
  padding-left: 10px;

  .admin-header-title {
    display: flex;
    flex-direction: row;
    font-size: 15px;
    gap : 10px;
  }
}

.admin-main-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 100%;
  overflow: hidden;


  * {
    user-select: auto;
    -moz-user-select: auto;
    -khtml-user-select: auto;
    -webkit-user-select: auto;
    -o-user-select: auto;
  }

  div,span,a,u,strong,blockquote,ul,li {
    user-select: text;
    -moz-user-select: text;
    -khtml-user-select: text;
    -webkit-user-select: text;
    -o-user-select: text;
  }
}


.admin-full-height {
  height: 100%;
  --height: 100%;
}

@media (max-width: 800px) {
  #admin-sub-conainer {
    display: none;
  }
}

.admin-full-container {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  max-width: 100wh;
  align-items: center;
  flex: 1;
  overflow-y: scroll;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}

.admin-grow-container {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  max-width: 100wh;
  align-items: center;
  flex: 1;
  overflow-y: scroll;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}

.admin-container {
  width: 100%;
  height: 100%;
  max-width: 800px;
  align-items: center;
  flex: 1;
  overflow-y: scroll;
  position: relative;
  -webkit-overflow-scrolling: touch;
}

.admin-sub-container {
  min-width: 50%;
}

.admin-card-container {
  width: 100%;
  height: 100%;
  max-width: 800px;
  padding: 10px;
  border-radius: 5px;
  border: 0.5px solid #dadada;
  margin: 10px 0px;
}

.admin-container-hidden {
  visibility: hidden;
  display: none !important;
}

.admin-input {
  flex-grow: 1;
  opacity: 1 !important;

  --padding-start: 0px;
  --padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
}

.admin-editor {
  border: 1px solid #000;
}

.admin-editor > .quill > .ql-container > .ql-editor {
  max-height: 200px !important;
}

.admin-image-show-n-change-container {
  position: relative;
}

.admin-image-show-n-change-file {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.ion-color-admin-button {
  flex-grow: 1;
  max-width: 100px;
  margin-top: 16px;
  margin-bottom: 16px;
  height: 20px;
  --ion-color-base: #fff;
  --border-radius: 12px;
  --border-width: 1.5px;
  --border-style: solid;
  --border-color: #2d9cdb;
  --padding-start: 10px;
  --padding-end: 10px;
  --box-shadow: none;
  font-size: 15px;
  line-height: 15px;

  color: #2d9cdb;
}

.admin-image-show-n-change-image {
  min-width: 100px;
}

.admin-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.admin-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin-grow {
  flex-grow: 1;
}

.admin-align-start {
  align-items: flex-start;
}

.admin-border {
  border: 0.5px solid #dadada;
}

.admin-margin-bottom {
  margin-bottom: 10px;
}

.admin-margin-top {
  margin-top: 10px;
}

.admin-item {
  padding: 10px;
}

.admin-large-font {
  font-weight: 800;
  font-size: 25px;
}

.admin-item-container {
  margin-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
  border-radius: 10px;
}

.admin-file-button {
  --icon-color: #fff;
  background: #2d9cdb;
  --icon-size: 16px;
  width: 30px;
  height: 30px;
  border-radius: 5px;
}

.admin-full-button-block {
  width: 100%;
  border: 1px solid #888;
  background: #dadada;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin-normal-text {
  font-size: 15px;
  line-height: 20px;
  font-weight: normal;
}

.admin-title {
  font-size: 18px;
  line-height: 20px;
  font-weight: 800;
  margin-right: 5px;
}

.admin-text-button {
  font-size: 13px;
  line-height: 15px;
  font-weight: normal;
  padding: 3px 10px;
  color: #2d9cdb;
}

.admin-search-box {
  background: #fee;
  border: 1px solid #dadada;
  padding: 10px 5px;
}

.admin-show-box {
  background: #efe;
  border: 1px solid #dadada;
  padding: 10px 5px;
}

.admin-margin-right {
  margin-right: 10px;
}

.admin-toggle-selected {
  background: #2d9cdb !important;
  color: #f2f2f2 !important;
}

.admin-toggle {
  height: 20px;
  padding: 5px 10px;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-left: 2px;
  margin-right: 2px;
  font-size: 11px;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.02em;

  background: #fff;
  color: #888888;
}

.admin-table {
  min-width: 100%;
  border: 1px solid #aaa;
  border-collapse: collapse;

  font-size: 15px;
  line-height: 20px;
  font-weight: normal;
  color: #000;
}

.admin-table-fixed-header {
  position: sticky;
  top: 0;
  z-index: 5;
}

.admin-table-label-x {
  font-size: 10px;
  font-weight: bold;
  color: #000;
  background: #eff;
  border: 1px solid #aaa;
  max-width: 80px;
  padding: 0px 5px;
  text-align: center;
  white-space: nowrap;
}

.admin-table-label-y {
  background: #fef;
  border: 1px solid #aaa;
  padding-left: 0px 5px;
  text-align: center;
  white-space: nowrap;
}

.admin-table-value {
  border: 1px solid #aaa;
  text-align: center;
  white-space: nowrap;
  &:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.02);
  }
}

.admin-table-value-text {
  border: 1px solid #aaa;
  text-align: left;
  padding-left: 10px;
  white-space: nowrap;
}

.admin-table-value-highlight {
  background-color: rgb(255, 255, 192) !important;
}

.admin-table-value-selected {
  background-color: rgb(223, 238, 252) !important;
  font-weight: bold;
  border: 2px solid #000;
}

.admin-table-value-dimm {
  color: #888;
}


.admin-table-value-align-space-between {
  justify-content: space-between;
}

.admin-table-value-picked {
  color: rgb(17, 35, 94);
  background: rgb(242, 249, 255);
}

.admin-table-value-canceld {
  color: #f00;
  text-decoration: line-through;
}

.admin-table-value-higlight {
  background: #eef;
  color: #00f;
}

.admin-table-value-caution {
  background: #fee;
  color: #f00;
  // tr line color set red
  border: 1px solid #f00;
}

tr:hover td.admin-table-value {
  text-align: center;
  white-space: nowrap;
  background: #ffe;
  font-weight: bold;
}

tr:hover td table tr td.admin-table-value {
  text-align: center;
  white-space: nowrap;
  background: #fff;
  font-weight: normal;
}

tr:hover td table tr:hover td.admin-table-value {
  text-align: center;
  white-space: nowrap;
  background: #ffe;
  font-weight: bold;
}

.admin-container-scroll-x {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}
.admin-container-scroll-x::-webkit-scrollbar {
  display: inline-table !important;
  width: 3px;
  height: 10px;
  background: none;
}
.admin-container-scroll-x::-webkit-scrollbar-thumb {
  background: #fdd;
  opacity: 0.4;
}
.admin-container-scroll-x::-webkit-scrollbar-track {
  background: none;
}

.admin-container-list-max-height {
  max-height: 500px;
}

.admin-keyword-item-container {
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.admin-keyword-item-type {
  color: #2d9cdb;
  display: flex;
  padding-top: 3px;
  flex-direction: column;
  align-items: center;
  min-width: 35px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #2d9cdb;
  font-weight: 800;

  font-size: 10px;
  line-height: 14px;
  margin-right: 10px;
  letter-spacing: -0.04em;
}

.admin-keyword-item-info {
  flex-grow: 1;
}

.admin-keyword-item-name {
  font-weight: 800;

  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.04em;
  color: #171717;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.admin-text-area {
  width: 100%;
}

.admin-margin-height {
  height: 100px;
}

.admin-container-border {
  width: 100%;
  padding: 5px;
  margin-bottom: 5px;
  border: 1px solid rgb(24, 160, 191);
}
